import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';

function EditCategoriesModel(props) {
    console.log("pro",props.editData);
    
    const [category, setCategory] = useState(props.editData.category_name);
    const [error, setError] = useState("");


    const editCategory = (e) => {
        setCategory(e.target.value);
        setError(""); 
    };


    const handleSubmit = () => {
        if (!category) {
            setError("Category name cannot be empty");
        } else {
            console.log("Category added:", category);
            props.onHide(); 
        }
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Category
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Enter Category Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Category Name"
                            value={category}
                            onChange={editCategory}
                            isInvalid={!!error}  
                        />
                        {error && (
                            <Form.Control.Feedback type="invalid">
                                {error}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="button"
                    variant="outline-success"
                    className="f-14"
                    onClick={handleSubmit}
                >
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditCategoriesModel;
