import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { Form, Row, Col, Button, Image } from 'react-bootstrap';

function AddArtistModel(props) {
    const [error, setError] = useState("");
    const [artistData, setArtistData] = useState({
        name: '',
        email: '',
        mobile: '',
        address: '',
        about: '',
        image: ''
    });

    // Handle input change for text fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setArtistData({
            ...artistData,
            [name]: value,
        });
    };

    // Handle image change (from URL or file input)
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setArtistData({
                    ...artistData,
                    image: reader.result, // base64 encoded image string
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Artist
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formArtistName">
                                <Form.Label>Artist Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={artistData.name}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formArtistEmail">
                                <Form.Label>Artist Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={artistData.email}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formArtistMobile">
                                <Form.Label>Artist Mobile</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="mobile"
                                    value={artistData.mobile}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formArtistAddress">
                                <Form.Label>Artist Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    value={artistData.address}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formArtistAbout">
                                <Form.Label>About Artist</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="about"
                                    value={artistData.about}
                                    onChange={handleInputChange}
                                    rows={8}  // Set the number of rows for the textarea
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formArtistImage">
                                <Form.Label>Artist Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                                <Image 
                                    src={artistData.image} 
                                    fluid 
                                    className="mt-3" 
                                    style={{ width: "150px", height: "150px" }} 
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="button"
                    variant="outline-success"
                    className="f-14"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddArtistModel;
