import React from 'react';
import { Container,Row,Col} from "react-bootstrap";
import { useData } from '../../context/DataContext';
import { Paper } from '@mui/material';
import MaterialReactTableWithSearch from '../MaterialTable/MaterialReactTableWithSearch';
import Loading from '../Loading/Loading';


function Donations() {
    const { donationsData, loading, error,totalDonationsAmount } = useData(); 
    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }


    if (!donationsData) {
        return <div>No user data available.</div>;
    }

    const columns = [
        {
            accessorFn: (row, index) => index + 1,
            header: 'SNO',
            id: 'id',
        },
        { accessorKey: 'name', header: 'Name' },
        { accessorKey: 'email', header: 'Email' },

        {
            accessorKey: 'mobile',
            header: 'Mobile',
            Cell: ({ cell }) => {
                const formatPhoneNumber = (number) =>
                    number ? number.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : '';

                return <span>{formatPhoneNumber(cell.getValue())}</span>;
            },
        },
        {
            accessorKey: 'amount',
            header: 'Amount',
            Cell: ({ cell }) => `$${cell.getValue().toFixed(2)}`, 
        },
        {
            accessorKey: 'inserted_time',
            header: 'Registered Date',
            Cell: ({ cell }) => {
                const formatDateTimeToUS = (dateString) => {
                    const date = new Date(dateString);
                    return date.toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    }).replace(',', ''); 
                };

                return <span>{formatDateTimeToUS(cell.getValue() || '')}</span>;
            },
        }
    ];

    return (
        <Container>
            <p className='main-heading-pages text-uppercase'>Donated Users</p>
            <Paper style={{ padding: 20 }}>
                <div className='mb-3'>
                    <Row>
                        <Col sm={4}>
                            <h4>Total Donated Users : <span className='fw-bold color-orange'>{donationsData.length}</span></h4> 
                        </Col>
                        <Col sm={4}>
                            <h4>Total Donated Amount : <span className='fw-bold color-orange'>${totalDonationsAmount}</span></h4> 
                        </Col>
                    </Row>
                </div>
                <MaterialReactTableWithSearch
                    columns={columns}
                    data={donationsData}

                />
            </Paper>
        </Container>
    );
}

export default Donations;
