import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { Form, Row, Col, Button, Image } from 'react-bootstrap';

function AddProductModal(props) {
    const [error, setError] = useState("");
    const [productData, setProductData] = useState({
        product_name: '',
        product_image: '',
        product_cost: '',
        image_dimensions: '',
        product_description: '',
        product_short_description: '',
        product_discount_percentage: '',
        product_final_price: '',
        product_artist: ''
    });

    const [thumbnailImages, setThumbnailImages] = useState([]); // State for multiple thumbnails

    // Handle input change for text fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProductData({
            ...productData,
            [name]: value,
        });
    };

    // Handle image change (file input)
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProductData({
                    ...productData,
                    product_image: reader.result, // base64 encoded image string
                });
            };
            reader.readAsDataURL(file);
        }
    };

    // Handle multiple thumbnails change (file input)
    const handleThumbnailChange = (e) => {
        const files = Array.from(e.target.files);
        const promises = files.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result); // base64 encoded string
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });

        Promise.all(promises).then((images) => {
            setThumbnailImages([...thumbnailImages, ...images]);
        }).catch((error) => {
            console.error("Error uploading thumbnails: ", error);
        });
    };

    // Handle removing a thumbnail
    const handleRemoveThumbnail = (indexToRemove) => {
        setThumbnailImages(thumbnailImages.filter((_, index) => index !== indexToRemove));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Submitted Product Data:", productData);
        console.log("Submitted Thumbnail Images:", thumbnailImages);
        // Add any validation logic or submission process
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Product
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formProductName">
                                <Form.Label>Product Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="product_name"
                                    value={productData.product_name}
                                    onChange={handleInputChange}
                                    placeholder="Enter product name"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formProductArtist">
                                <Form.Label>Product Artist</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="product_artist"
                                    value={productData.product_artist}
                                    onChange={handleInputChange}
                                    placeholder="Enter product artist"
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formProductCost">
                                <Form.Label>Product Cost</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="product_cost"
                                    value={productData.product_cost}
                                    onChange={handleInputChange}
                                    placeholder="Enter product cost"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formProductDimensions">
                                <Form.Label>Image Dimensions</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="image_dimensions"
                                    value={productData.image_dimensions}
                                    onChange={handleInputChange}
                                    placeholder="Enter dimensions (e.g., 24 W x 36 H in.)"
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formProductFinalPrice">
                                <Form.Label>Final Price</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="product_final_price"
                                    value={productData.product_final_price}
                                    onChange={handleInputChange}
                                    placeholder="Enter final price"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formProductDiscount">
                                <Form.Label>Product Discount (%)</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="product_discount_percentage"
                                    value={productData.product_discount_percentage}
                                    onChange={handleInputChange}
                                    placeholder="Enter discount percentage"
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formProductDescription">
                                <Form.Label>Product Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="product_description"
                                    value={productData.product_description}
                                    onChange={handleInputChange}
                                    rows={4}
                                    placeholder="Enter a detailed product description"
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group controlId="formProductShortDescription">
                                <Form.Label>Product Short Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="product_short_description"
                                    value={productData.product_short_description}
                                    onChange={handleInputChange}
                                    rows={4}
                                    placeholder="Enter a short description"
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formProductImage">
                                <Form.Label>Product Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                                {productData.product_image && (
                                    <Image
                                        src={productData.product_image}
                                        fluid
                                        className="mt-3"
                                        style={{ width: "150px", height: "150px" }}
                                    />
                                )}
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formProductThumbnails">
                                <Form.Label>Product Thumbnails</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handleThumbnailChange} // Multiple files handler
                                />
                                {thumbnailImages.length > 0 && (
                                    <div className="mt-3">
                                        {thumbnailImages.map((image, index) => (
                                            <div key={index} style={{ display: 'inline-block', position: 'relative', marginRight: '10px' }}>
                                                <Image
                                                    src={image}
                                                    fluid
                                                    style={{ width: "100px", height: "100px" }}
                                                />
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '5px',
                                                        right: '5px',
                                                        borderRadius: '50%',
                                                    }}
                                                    onClick={() => handleRemoveThumbnail(index)}
                                                >
                                                    &times;
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="button"
                    variant="outline-success"
                    className="f-14"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddProductModal;
