import Cookies from 'js-cookie'; 

const fetchFromApi = async (endpoint, method = 'POST', body = null) => {
    const options = {
        method,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    };

    const token = document.cookie.split('; ').find(row => row.startsWith('artfroAdminToken='));
    const tokenValue = token ? token.split('=')[1] : null;

    if (tokenValue && endpoint !== '/login') {
        options.headers["Authorization"] = `Bearer ${tokenValue}`;
    }

    if (method === 'POST' && body) {
        options.body = new URLSearchParams(body).toString();
    } else if (method === 'GET' && body) {
        const urlParams = new URLSearchParams(body).toString();
        endpoint += `?${urlParams}`;
    }

    try {
        const response = await fetch(endpoint, options);

        // Handle 401 response
        if (response.status === 401) {
            Cookies.remove('artfroAdminToken'); 
            Cookies.remove('artfroAdminUser');
            window.location.href = '/'; 
            return; 
        }

        if (!response.ok) {
            throw new Error(`Network response was not ok. Status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Fetch error:', error);
        throw new Error('Network error or server is down');
    }
};

export { fetchFromApi };
