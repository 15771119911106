import React, { useState } from 'react';
import { Container, Button } from "react-bootstrap";
import { useData } from '../../context/DataContext';
import { Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Tooltip } from '@mui/material';
import AddCategoriesModel from './AddCategoriesModel';
import ModeEditOutlineSharpIcon from '@mui/icons-material/ModeEditOutlineSharp';
import EditCategoriesModel from './EditCategoriesModel';
import MaterialReactTableWithSearch from '../MaterialTable/MaterialReactTableWithSearch';
import Loading from '../Loading/Loading';


function Categories() {

    const { categoriesData, loading, error } = useData();
    const [modalShow, setModalShow] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState({});

    if (loading) {
        return <Loading />;
    }


    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!categoriesData) {
        return <div>No categories data available.</div>;
    }

    const activeEditModal = (row) => {
        setEditData(row.original)
        setEditModal(true)
    }

    const deleteRow = (row) => {
        if (window.confirm("Are you sure you want to delete category ?")) {
            console.log(`Deleting row with ID: ${row.original.id}`);
        }
    };



    const renderRowActions = ({ row }) => (
        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
            <Tooltip title="Edit">
                <IconButton>
                    <ModeEditOutlineSharpIcon onClick={() => activeEditModal(row)} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
                <IconButton>
                    <DeleteIcon className='text-danger' onClick={() => deleteRow(row)} />
                </IconButton>
            </Tooltip>
        </Box>
    );

    const columns = [
        {
            accessorFn: (row, index) => index + 1,
            header: 'SNO',
            id: 'id',
        },
        {
            accessorKey: 'category_name',
            header: 'Category Name',
        },
        {
            accessorKey: 'inserted_time',
            header: 'Inserted Date',
            Cell: ({ cell }) => {
                const formatDateTimeToUS = (dateString) => {
                    const date = new Date(dateString);
                    return date.toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    }).replace(',', ''); // Remove comma for cleaner format
                };

                return <span>{formatDateTimeToUS(cell.getValue() || '')}</span>;
            },
        },
        {
            accessorKey: 'updated_time',
            header: 'Updated Date',
            Cell: ({ cell }) => {
                const formatDateTimeToUS = (dateString) => {
                    const date = new Date(dateString);
                    console.log("date", date);
        
                    // Check if the date is valid
                    return isNaN(date.getTime()) ? "-" : date.toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    }).replace(',', '');
                };
        
                return <span>{formatDateTimeToUS(cell.getValue() || '')}</span>;
            },
        },        
        {
            id: 'actions',
            header: 'Actions',
            Cell: (props) => renderRowActions(props),
            size: 100,
            muiTableBodyCellProps: {
                sx: {
                    position: 'sticky',
                    right: 0,
                    backgroundColor: '#fff',
                    zIndex: 1,
                },
            },
            muiTableHeadCellProps: {
                sx: {
                    position: 'sticky',
                    right: 0,
                    backgroundColor: '#fff',
                    zIndex: 1,
                },
            },
        }
    ];

    return (
        <Container>
            <div className='d-flex justify-content-between'>
                <div>
                    <p className='main-heading-pages text-uppercase'>Categories</p>
                </div>
                <div className='p-2'>
                    <Button type="button" variant="outline-success" className="f-14" onClick={() => setModalShow(true)}>
                        Add Category
                    </Button>
                </div>
            </div>

            <Paper style={{ padding: 20, height: '500px', overflowY: 'auto' }}>
                <MaterialReactTableWithSearch
                    columns={columns}
                    data={categoriesData}
                />
            </Paper>
            {
                modalShow ? < AddCategoriesModel show={modalShow}
                    onHide={() => setModalShow(false)} /> : ""

            }
            {
                editModal && (
                    <EditCategoriesModel
                        show={editModal}
                        editData={editData}
                        onHide={() => {
                            setEditModal(false);
                            setEditData({});
                        }}
                    />
                )
            }

        </Container>

    );
}

export default Categories;
