import React, { useState } from 'react';
import { Container, Spinner, Button } from "react-bootstrap";
import { useData } from '../../context/DataContext';
import { MaterialReactTable } from 'material-react-table';
import { Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Tooltip } from '@mui/material';
import ModeEditOutlineSharpIcon from '@mui/icons-material/ModeEditOutlineSharp';
import AddProductModal from './AddProductModal';
import EditProductModal from './EditProductModal';

function Products() {
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState({});

    const { productsData, loading, error, refetch } = useData(); // Assuming refetch function exists

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <p>Error: {error}</p>
                <Button onClick={refetch}>Retry</Button>
            </div>
        );
    }

    if (!productsData) {
        return <div>No user data available.</div>;
    }

    const activeEditModal = (row) => {
        console.log("rowwww",row);
        
        setEditData(row.original);
        setEditModal(true);
    }

    const deleteRow = (row) => {
        if (window.confirm("Are you sure you want to delete category ?")) {
            console.log(`Deleting row with ID: ${row.original.id}`);
        }
    };


    const renderRowActions = ({ row }) => (
        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
            <Tooltip title="Edit">
                <IconButton>
                    <ModeEditOutlineSharpIcon onClick={() => activeEditModal(row)} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
                <IconButton>
                    <DeleteIcon className='text-danger' onClick={() => deleteRow(row)} />
                </IconButton>
            </Tooltip>
        </Box>
    );

    const columns = [
        {
            accessorFn: (row, index) => index + 1,
            header: 'SNO',
            id: 'id', 
        },
        {
            accessorKey: 'product_image',
            header: 'Product Image',
            Cell: ({ cell }) => (
                <img
                    src={cell.getValue()}
                    alt="Product"
                    style={{ width: '100px', height: '100px', borderRadius: '5px' }}
                />
            ),
        },
        { accessorKey: 'product_name', header: 'Product Name' },
        { accessorKey: 'product_description', header: 'Product Description' },
        { accessorKey: 'product_short_description', header: 'Product Short Description' },
        { accessorKey: 'image_dimensions', header: 'Image Dimensions' },
        {
            accessorKey: 'product_final_price',
            header: 'Price',
            Cell: ({ cell }) => `$${cell.getValue()}`,
        },
        {
            id: 'actions',
            header: 'Actions',
            Cell: (props) => renderRowActions(props),
            size: 100, // Control the width of the actions column
            muiTableBodyCellProps: {
                sx: {
                    position: 'sticky',
                    right: 0,
                    backgroundColor: '#fff', // Set background to prevent content overlap
                    zIndex: 1, // Ensure the column stays on top when scrolling
                },
            },
            muiTableHeadCellProps: {
                sx: {
                    position: 'sticky',
                    right: 0,
                    backgroundColor: '#fff', // Set background to prevent content overlap
                    zIndex: 1,
                },
            },
        }
        
    ];

    return (
        <Container>
            <div className='d-flex justify-content-between'>
                <div>
                    <p className='main-heading-pages'>Products</p>
                </div>
                <div className='p-2'>
                    <Button type="button" variant="outline-success" className="f-14"  onClick={() => setAddModal(true)}>
                        Add Product
                    </Button>
                </div>
            </div>
            <Paper style={{ height: '500px', overflowY: 'auto',padding: 20  }}>
                <MaterialReactTable
                    columns={columns}
                    data={productsData}
                    enableSorting
                    enablePagination
                />
            </Paper>
            {
                addModal && (
                    <AddProductModal
                        show={addModal}
                        onHide={() => {
                            setAddModal(false);
                        }}
                    />
                )
            }
             {
                editModal && (
                    <EditProductModal
                        show={editModal}
                        editData={editData}
                        onHide={() => {
                            setEditModal(false);
                            setEditData({});
                        }}
                    />
                )
            }
        </Container>
    );
}

export default Products;
