const API_BASE_URL = "https://artfrocollection.com/php/artadmin";

const API_ENDPOINTS = {
    LOGIN: `${API_BASE_URL}/adminLogin.php`,
    SUBSCRIBERS: `${API_BASE_URL}/getData.php?api=subscribers`,
    DONATIONS: `${API_BASE_URL}/getData.php?api=donations`,
    REG_USERS: `${API_BASE_URL}/getData.php?api=users`,
    PRODUCTS: `${API_BASE_URL}/getData.php?api=products`,
    PRODUCT_CATEGORIES: `${API_BASE_URL}/getData.php?api=productCategories`,
    ARTISTS: `${API_BASE_URL}/getData.php?api=artists`,
    ENQURIES: `${API_BASE_URL}/getData.php?api=enquiries`,
    ORDERS: `${API_BASE_URL}/getData.php?api=orders`,
};

export default API_ENDPOINTS;
